import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useLocation, Link } from 'react-router-dom';
import logoIndex from '../../../assets/logos/indexblanco.svg';
import MenuButton from '../MenuButton/MenuButton';
import Menu from '../Menu/Menu';
import Portal from '../HOC/Portal';
import Grid from '../../../assets/Icons/fin.svg';

const style = {
  position: 'fixed',
  top: 0,
  width: '100%',
};

const logoVariants = {
  close: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },

  open: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
};

const Header = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [logoRojo, setLogoRojo] = useState();
  useEffect(() => {
    location.pathname !== undefined && location.pathname !== '/'
      ? setLogoRojo(true)
      : setLogoRojo(false);
  }, [location.pathname]);

  const headerClasses = {
    red: ' flex flex-row items-center justify-between w-full',
    white: 'flex flex-row items-center justify-between w-full bg-indexred',
  };

  return (
    <>
      <div
        className="h-16 flex flex-col justify-between items-center absolute fixed bg-fixed w-full z-20"
        style={style}
      >
        <div className={logoRojo ? headerClasses.white : headerClasses.red}>
          <Link to="/">
            <motion.div
              variants={logoVariants}
              initial={false}
              animate={showMenu ? 'open' : 'close'}
              className=" h-full w-auto ml-5 md:ml-10 p-3 justify-center flex flex row cursor-pointer"
            >
              <img className="w-16 sm:w-20" src={logoIndex} alt="Logo" />
            </motion.div>
          </Link>

          <div className="flex flex-row justify-center items-center">
            <Link
              onClick={showMenu ? () => setShowMenu(false) : null}
              className="cursor-pointer"
              to="/portfolio"
            >
              <img src={Grid} className="h-5 w-5 mr-3" alt="" />
            </Link>

            <MenuButton
              setShowMenu={() => setShowMenu(!showMenu)}
              showMenu={showMenu}
            />
          </div>
        </div>
      </div>

      <Portal id="MHeader">
        <Menu showMenu={showMenu} setShowMenu={() => setShowMenu(!showMenu)} />
      </Portal>
    </>
  );
};

export default Header;
