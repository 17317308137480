const produccionAudiovisual = {
  Preproduccion: [
    {
      Titulo: 'Idea / tratamiento',
      Descripcion:
        'El tratamiento audiovisual toma forma a partir de la creatividad obtenida con un briefing eficaz.',
    },
    {
      Titulo: 'Guión técnico / story',
      Descripcion:
        'Partimos del desglose en un guión técnico para detallar disciplinas técnicas.',
    },
    {
      Titulo: 'Equipo técnico y creativo',
      Descripcion:
        'Enumeramos y cerramos el equipo humano y los medios que requiera el proyecto. ',
    },
    {
      Titulo: 'Localizaciones',
      Descripcion:
        'Realizador y responsable de producción buscan, seleccionan y visitan los posibles escenarios donde rodar.',
    },
    {
      Titulo: 'Casting',
      Descripcion:
        'Seleccionamos modelos, actores, actrices y figurantes según los perfiles requeridos.',
    },
    {
      Titulo: 'Arte / atrezzo',
      Descripcion:
        'Dirección de arte y selección de atrezzo son fundamentales para definir la estética de la pieza audiovisual.',
    },
    {
      Titulo: 'Estilismo / vestuario / makeup',
      Descripcion:
        'El lookbook es el documento de referencia que se genera para coordinar el estilismo deseado.',
    },
    {
      Titulo: 'Gestión cesión dchos. / imagen',
      Descripcion:
        'Tramitamos los distintos documentos legales de cesión de derechos para tenerlo todo listo antes de rodar.',
    },
    {
      Titulo: 'Plan de rodaje',
      Descripcion:
        'Un plan bien elaborado y detallado es clave para garantizar un rodaje eficaz.',
    },
  ],
  Postproduccion: [
    {
      Titulo: 'Ingesta y backup',
      Descripcion:
        'Un rodaje no termina hasta que se vuelca todo el material generado. INDEX cuenta con un sistema de backups diarios para garantizar la integridad de los datos.',
    },
    {
      Titulo: 'Selección musical y audio FX',
      Descripcion:
        'La edición sonora es uno de los procesos clave de una producción. Trabajamos sólo con bancos de música y FX profesionales. ',
    },
    {
      Titulo: 'Edición',
      Descripcion:
        'INDEX cuenta con una completa sala de edición para confort de nuestros clientes, del realizador y del director de fotografía.',
    },
    {
      Titulo: 'Motion Graphics / 3D',
      Descripcion:
        'Integramos distintas disciplinas infográficas: ilustración animada, motion graphics, 3D, etcétera. gracias a nuestro equipo de creativos infográficos freelance.',
    },
    {
      Titulo: 'Etalonaje',
      Descripcion:
        'Etalonamos todos nuestros trabajos. La corrección técnica y creativa del aspecto visual de los planos es esencial para potenciar las distintas atmósferas deseadas.',
    },
    {
      Titulo: 'Estudio de sonido',
      Descripcion:
        'INDEX cuenta con un estudio de sonido propio que incluye un locutorio insonorizado. Además de sonorización de vídeos, realizamos producciones íntegras de sonido.',
    },
    {
      Titulo: 'Locución',
      Descripcion:
        'Elegimos la voz y el tono más sugerente para que el mensaje emocione.',
    },
    {
      Titulo: 'Producción idiomas',
      Descripcion:
        'Cuidamos la integridad del mensaje cuando el vídeo va dirigido a otros países. Por eso sólo contratamos locutores profesionales nativos.',
    },
    {
      Titulo: 'Masterización, formatos y versiones',
      Descripcion:
        'Renderizamos la pieza definitiva y la adaptamos a cada formato que los medios y redes requieren.',
    },
  ],
  Produccion: [
    {
      Titulo: 'Equipo de cámara',
      Descripcion:
        'Disponemos en cada producción de los distintos seteos técnicos según las necesidades del realizador / director de fotografía.',
    },
    {
      Titulo: 'Equipo de iluminación',
      Descripcion:
        'Contamos con equipamiento LED, pantallas, fresnel, soportes… y solo alquilamos con los mejores proveedores si la producción lo requiere.',
    },
    {
      Titulo: 'Sonido',
      Descripcion:
        'La adecuada captación del sonido directo cuando hay diálogos y entrevistas es siempre un elemento diferenciador de calidad, clave en cualquier producción.',
    },
    {
      Titulo: 'Equipo de producción',
      Descripcion:
        'Coordinar el rodaje, asegurar el cumplimiento de los timings y tener siempre un plan B.',
    },
    {
      Titulo: 'Catering y desplazamientos',
      Descripcion:
        'Coordinamos la logística de catering y desplazamientos para asegurar el confort y disponibilidad de todo el equipo.',
    },
  ],
};

export default produccionAudiovisual;
