import axios from 'axios';

const API = axios.create({
  baseURL: 'https://api.vimeo.com',
  headers: {
    // Authorization: 'bearer ' + 'f5e8934ef044df9bcc7299c4efe4b6a7',
    Authorization: 'bearer ' + '3aa945e26fdb3b58ca472f4b37e02ade',
    'Content-type': 'application/json',
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
  },
});
export { API };
