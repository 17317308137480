import React from 'react';
import { motion } from 'framer-motion';
import indexLogo from '../../../assets/logos/indexblanco.svg';
import vimeo from '../../../assets/logos/vimeo_1.svg';
import instagram from '../../../assets/logos/instagram.svg';
import facebook from '../../../assets/logos/facebook.svg';

const Footer = ({ setShowPolitica, setShowCookiePol, setShowAviso }) => {
  return (
    <div className="flex bg-black w-full bottom-0">
      <div className="flex flex-col md:flex-row justify-around items-center bg-indexred w-full h-fit p-5">
        <div className="flex flex-col h-fit m-5">
          <img src={indexLogo} className="h-12" alt="" />
          <div className="flex flex-col mt-3">
            <p className="font-sanfran text-center text-sm text-white my-2 font-light">
              C/ Font de la Uxola, 4. 03803 Alcoy (Alicante)
            </p>
            <p className="font-sanfran text-center text-sm text-white my-2 font-light">
              965 522 675 - 670 638 921
            </p>
            <motion.a
              whileHover={{ scale: 1.02 }}
              href="mailto:contacto@indexvideo.es"
              className="font-sanfran text-center text-sm text-white my-2 font-light"
            >
              contacto@indexvideo.com
            </motion.a>
          </div>
          <div className="flex flex-row w-full justify-center my-4">
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.instagram.com/index.video/?hl=es"
            >
              <img src={instagram} alt="instagram" className="h-6 mx-4" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.facebook.com/index.audiovisual/"
            >
              <img src={facebook} alt="facebook" className="h-6 mx-4" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://vimeo.com/sobreindex"
            >
              <img src={vimeo} alt="vimeo" className="h-6 mx-4" />
            </motion.a>
          </div>
          <div className="flex flex-row">
            <p
              onClick={() => setShowPolitica(true)}
              className="font-sanfran text-center text-sm text-white my-2 font-light cursor-pointer mx-5"
            >
              Politíca de privacidad
            </p>
            <p
              onClick={() => setShowCookiePol(true)}
              className="font-sanfran text-center text-sm text-white my-2 font-light cursor-pointer mx-5"
            >
              Politíca de Cookies
            </p>
            <p
              onClick={() => setShowAviso(true)}
              className="font-sanfran text-center text-sm text-white my-2 font-light cursor-pointer mx-5"
            >
              Aviso Legal
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
