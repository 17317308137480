import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import Indi from '../../../assets/logos/indinegro.svg';

const Modal = ({ position, setShowModal, description }) => {
  const posicion = {
    x: position.x,
    y: position.y + 50,
  };

  const modalAnimation = useAnimation();
  const bgAnimation = useAnimation();

  const capaNegra = {
    backgroundColor: `rgba(0,0,0,0.25)`,
  };

  const animationSeq = state => {
    if (state === 'in') {
      modalAnimation.start({
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, type: 'spring', stiffness: 100 },
      });
      bgAnimation.start({
        opacity: 1,
        transition: { duration: 0.5 },
      });
    } else {
      bgAnimation.start({
        opacity: 0,
        transition: { duration: 0.5 },
      });
      modalAnimation
        .start({
          y: -100,
          opacity: 0,
          // scale: 0,
          transition: { duration: 0.2, type: 'tween' },
        })
        .then(() => setShowModal(false));
    }
  };

  useEffect(() => {
    animationSeq('in');

    return () => {
      animationSeq('out');
    };
  });

  return (
    <motion.div
      style={{ zIndex: 40 }}
      onWheel={() => animationSeq('out')}
      onClick={() => animationSeq('out')}
      onTouchMove={() => animationSeq('out')}
      className="flex  justify-center items-center h-screen w-full mx-auto fixed  "
    >
      <motion.div
        style={capaNegra}
        animate={bgAnimation}
        className="flex justify-center items-center h-screen w-full relative"
      >
        <motion.div
          onWheel={() => animationSeq('out')}
          onClick={() => animationSeq('out')}
          onTouchMove={() => animationSeq('out')}
          initial={{ y: -100, opacity: 0 }}
          animate={modalAnimation}
          style={{ borderRadius: '10px' }}
          className="flex w-10/12 bg-white sm:w-6/12 lg:w-5/12 xl:w-4/12 h-auto absolute overflow-hidden p-6 modalSombra"
        >
          <p className="whitespace-pre-line text-gray-600 font-sanfran font-thin">
            {description}
          </p>
          <img
            src={Indi}
            style={{ bottom: '-2.5rem', left: '-0.5rem', opacity: 0.063 }}
            className=" h-64 absolute "
            alt=""
          />
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
