import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import AOS from 'aos';
import lottie from 'lottie-web';

import animationData from '../../../assets/animatedIcons/scrollrojo.json';
import 'aos/dist/aos.css';
import Persona from './Persona';
import teamInfo from './teamInfo';
import { gtag, GA_MEASUREMENT_ID } from '../Analitycs/Analitycs';

AOS.init();

export const Equipo = () => {
  gtag('config', GA_MEASUREMENT_ID, { page_path: '/Equipo.html' });

  const page = useRef(null);
  const animBox = useRef(null);
  let animObj = null;

  useEffect(() => {
    animObj = lottie.loadAnimation({
      container: animBox.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      ref={page}
      className="bg-white back w-full p-header px-0 pb-0 justify-center items-center"
    >
      <div className="flex flex-row mb-6 w-full bottom-0 my-auto justify-center items-center fixed">
        <div ref={animBox} className="flex w-16  absolute  m-auto" />
      </div>
      {teamInfo.map((persona, i) => {
        return (
          <Persona
            key={i}
            index={i}
            imagen={persona.imagen}
            descripcion={persona.texto}
            nombre={persona.nombre}
            email={persona.email}
            puesto={persona.puesto}
          />
        );
      })}
    </div>
  );
};

export default Equipo;
