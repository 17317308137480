import React from 'react';
import closer from '../../../assets/Icons/Close.svg';

const Aviso = ({ setShowAviso }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full py-8 md:p-header fixed z-20 ">
      <div className="flex flex-col h-full w-full relative bg-white rounded-lg p-8 pt-4 pr-0 z-20 m-5">
        <div
          style={{ height: '1.25rem' }}
          className="flex flex-row w-full items-center justify-end pr-10 my-3"
        >
          <img
            onClick={() => setShowAviso(false)}
            src={closer}
            className="h-5 w-5 cursor-pointer"
            alt=""
          />
        </div>
        <div className="flex flex-col rounded-lg bg-white overflow-y-scroll mr-8">
          <p>
            El responsable de esta página web es: INDEX PRODUCCIONES, S.L.,
            sociedad inscrita en el Registro Mercantil de Alicante tomo 1678
            folio 217 hoja A-25646, con C.I.F. B03915626 y domicilio social en
            Font de la Uxola 4,03803, Alcoy (ALICANTE). También puedes contactar
            con nosotros a través del número de teléfono Nº 965522675 (teléfono)
            o a través de la siguiente dirección de e-mail:
            contacto@indexvideo.es (e-mail o enlace a formulario de contacto).
            Propiedad Intelectual e Industrial El diseño de este sitio web y sus
            códigos fuente, así como los logos, marcas y demás signos
            distintivos que aparecen en el mismo pertenecen a INDEX
            PRODUCCIONES, S.L. y están protegidos por los correspondientes
            derechos de propiedad intelectual e industrial. Responsabilidad de
            los contenidos INDEX PRODUCCIONES, S.L. no se hace responsable de la
            legalidad de otros sitios web de terceros desde los que pueda
            accederse a esta web. INDEX PRODUCCIONES, S.L. tampoco responde por
            la legalidad de otros sitios web de terceros, que pudieran estar
            vinculados o enlazados desde esta web. INDEX PRODUCCIONES, S.L. se
            reserva el derecho a realizar cambios en el sitio web sin previo
            aviso, al objeto de mantener actualizada su información, añadiendo,
            modificando, corrigiendo o eliminando los contenidos publicados o el
            diseño del portal. INDEX PRODUCCIONES, S.L. no será responsable del
            uso que terceros hagan de la información publicada en el portal, ni
            tampoco de los daños sufridos o pérdidas económicas que, de forma
            directa o indirecta, produzcan o puedan producir perjuicios
            económicos, materiales o sobre datos, provocados por el uso de dicha
            información. Reproducción de contenidos Se prohíbe la reproducción
            total o parcial de los contenidos publicados en esta web sin la
            autorización previa y por escrito de INDEX PRODUCCIONES, S.L.. Toda
            la información relativa a privacidad, la tienes en nuestra Política
            de Privacidad
          </p>
        </div>
      </div>
      <div className="w-full h-full bg-black opacity-25 absolute z-10" />
    </div>
  );
};

export default Aviso;
