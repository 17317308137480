import React from 'react';
import closer from '../../../assets/Icons/Close.svg';

const CookiePol = ({ setShowCookiePol }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full py-8 md:p-header fixed z-20 ">
      <div className="flex flex-col h-full w-full relative bg-white rounded-lg p-8 pt-4 pr-0 z-20 m-5">
        <div
          style={{ height: '1.25rem' }}
          className="flex flex-row w-full items-center justify-end pr-10 my-3"
        >
          <img
            onClick={() => setShowCookiePol(false)}
            src={closer}
            className="h-5 w-5 cursor-pointer"
            alt=""
          />
        </div>
        <div className="flex flex-col rounded-lg bg-white overflow-y-scroll mr-8">
          <p>
            INFORMACIÓN DE COOKIES: Las cookies son archivos que se pueden
            descargar en su equipo a través de las páginas web. Son herramientas
            que tienen un papel esencial para la prestación de numerosos
            servicios de la sociedad de la información. Entre otros, permiten a
            una página web almacenar y recuperar información sobre los hábitos
            de navegación de un usuario o de su equipo y, dependiendo de la
            información obtenida, se pueden utilizar para reconocer al usuario y
            mejorar el servicio ofrecido. TIPOS DE COOKIES: Según quien sea la
            entidad que gestione el dominio desde donde se envían las cookies y
            trate los datos que se obtengan se pueden distinguir dos tipos:
            cookies propias y cookies de terceros. Existe también una segunda
            clasificación según el plazo de tiempo que permanecen almacenadas en
            el navegador del cliente, pudiendo tratarse de cookies de sesión o
            cookies persistentes. Por último, existe otra clasificación con
            cinco tipos de cookies según la finalidad para la que se traten los
            datos obtenidos: cookies técnicas, cookies de personalización,
            cookies de análisis, cookies publicitarias y cookies de publicidad
            comportamental. COOKIES UTILIZADAS EN LA WEB: A continuación se
            identifican las cookies que están siendo utilizadas en este portal
            así como su tipología y función: Una cookie propia de tipo técnico
            denominada PHPSESSID necesaria para la correcta navegación por las
            páginas web. Cookies de Google Analytics, un servicio de analítica
            web desarrollada por Google, que permite la medición y análisis de
            la navegación en las páginas web. En su navegador podrá observar 4
            cookies de este servicio. Según la tipología anterior se trata de
            cookies propias, de sesión y de análisis. Puede encontrar más
            información al respecto e inhabilitar el uso de estas cookies en:
            http://www.google.es/intl/es_ALL/analytics/learn/privacy.html A
            través de la analítica web no se obtiene información sobre sus datos
            personales garantizando la tutela de la privacidad de las personas
            que navegan por la web. La información que se obtiene es la relativa
            al número de usuarios que acceden a la web, el número de páginas
            vistas, la frecuencia y repetición de las visitas, su duración, el
            navegador utilizado, el operador que presta el servicio, el idioma,
            el terminal que utiliza, o la ciudad a la que está asignada su
            dirección IP. Información que posibilita un mejor y más apropiado
            servicio por parte de este portal. CÓMO MODIFICAR LA CONFIGURACIÓN
            DE LAS COOKIES: Usted puede restringir, bloquear o borrar las
            cookies de este sitio o cualquier otra página web, utilizando su
            navegador. En cada navegador la operativa diferente, la función de
            Ayuda le mostrará cómo hacerlo. ● Internet Explorer:
            ​http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie="ie-10"
            ● FireFox: ​https://support.mozilla.org/es/kb/Borrar%20cookies ●
            Chrome: ​https://support.google.com/chrome/answer/95647?hl="es"
          </p>
        </div>
      </div>
      <div className="w-full h-full bg-black opacity-25 absolute z-10" />
    </div>
  );
};

export default CookiePol;
