/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import animationData from '../../../assets/animatedIcons/burguer.json';

let animObj = null;

const MenuButton = ({ setShowMenu, showMenu }) => {
  const animBox = useRef(null);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    animObj = lottie.loadAnimation({
      container: animBox.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData,
    });
  }, []);

  const playPause = play => {
    if (play === false || !showMenu) {
      animObj.playSegments([20, 90], true);
    } else if (play === true || showMenu) {
      animObj.playSegments([110, 180], true);
    }
    setPlay(!play);
  };

  useEffect(() => {
    if (showMenu) {
      animObj.playSegments([20, 90], true);
    } else {
      animObj.playSegments([110, 180], true);
    }
  }, [showMenu]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className="h-6 w-6 mr-5 md:mr-10 ml-2 cursor-pointer z-50 outline-none noSelect"
      onClick={() => {
        playPause(play), setShowMenu();
      }}
      ref={animBox}
    />
  );
};

export default MenuButton;
