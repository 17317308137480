/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
import { motion } from 'framer-motion';
import Close from '../../../assets/Icons/WClose.svg';
import Portal from '../HOC/Portal';
import Modal from '../Modal/Modal';

const transparency = {
  backgroundColor: 'rgba(0,0,0,0.8)',
};

const Reproductor = ({ idVideo, setShowVideo, showVideo }) => {
  const wrapper = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalPosition, setModalPosition] = useState();

  const createPlayer = () => {
    const vidPlayer = new Player(wrapper.current, {
      id: idVideo,
      autoplay: true,
      playsinline: false,
    });
    return vidPlayer;
  };

  useEffect(() => {
    const videoPlayer = createPlayer();
    videoPlayer.on('loaded', () => {
      const iframe = wrapper.current.childNodes[0];
      iframe.setAttribute('style', 'padding:0');
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '90%');
      iframe.setAttribute('position', 'absolute');
    });

    return () => {
      videoPlayer.destroy();
    };
  }, []);

  // useEffect(() => {
  //   if(modalPosition != undefined)
  // }, [showModal]);

  return (
    <div
      className=" h-screen w-full fixed z-50 flex flex-col justify-center items-center overflow-y-hidden overflow-x-hidden"
      style={transparency}
    >
      {showModal && (
        <Portal id="Modal">
          <Modal position={modalPosition} />
        </Portal>
      )}

      <div className="flex flex-row justify-end">
        <div className="z-50 cursor-pointer">
          <img
            src={Close}
            onClick={e => {
              e.preventDefault(), setShowVideo(!showVideo);
            }}
            className="h-8"
            alt=""
          />
        </div>
      </div>

      <div
        className="w-full h-p90 relative  flex justify-center items-center "
        ref={wrapper}
        id="wrapper"
      />
    </div>
  );
};

export default Reproductor;
