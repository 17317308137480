/* eslint-disable prefer-rest-params */
/* eslint-disable import/prefer-default-export */
export const GA_MEASUREMENT_ID = 'UA-47677131-1';

export function gtag() {
  window.dataLayer.push(arguments);
}

export function initGA(enabled) {
  window['ga-disable-UA-47677131-1'] = enabled;
  gtag('js', new Date());
  gtag('config', 'UA-47677131-1');
}
