/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { motion, useAnimation, useAnimatedState } from 'framer-motion';
import Portal from '../HOC/Portal';
import Reproductor from '../Player/Reproductor';
import playBtn from '../../../assets/Icons/Playbttn.svg';
import info from '../../../assets/Icons/infoicon.svg';
import Modal from '../Modal/Modal';

let modalPosition;
const LandingVideo = ({
  thumbnail,
  name,
  link,
  player,
  description,
  tags,
  idVideo,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const style = {
    backgroundImage: `url(${thumbnail}})`,
  };

  const sticky = {
    position: 'sticky',
    top: 0,
    width: '100%',
  };

  const capaNegra = {
    backgroundColor: `rgba(0,0,0,0.25)`,
  };

  const animationBackground = useAnimation();
  const animationPlayBtn = useAnimation();
  const animationOpacity = useAnimation();

  const triggerBackgroundAnimation = state => {
    if (state === 'up') {
      animationBackground.start({
        scale: 1.05,
        transition: { duration: 0.5, ease: 'easeInOut' },
      });
      animationPlayBtn.start({
        scale: 1.15,
        transition: { duration: 0.5 },
      });
      animationOpacity.start({
        backgroundColor: `rgba(0,0,0,0.15)`,
        transition: { duration: 0.5 },
      });
    } else {
      animationBackground.start({
        scale: 1,
        transition: { duration: 0.5, ease: 'easeInOut' },
      });
      animationPlayBtn.start({
        scale: 1,
        transition: { duration: 0.5 },
      });
      animationOpacity.start({
        backgroundColor: `rgba(0,0,0,0.25)`,
        transition: { duration: 0.5 },
      });
    }
  };

  const showModalPosition = e => {
    if (showModal === false) {
      const element = e.target;
      const position = element.getBoundingClientRect();
      modalPosition = position;
      setShowModal(!showModal);
    }
    if (showModal) {
      setShowModal(false);
    }
  };

  return (
    <motion.div
      // initial={{ backgroundSize: '110%' }}
      className="w-full overflow-hidden relative bg-cover bg-no-repeat object-cover h-screen bg-cover bg-center sticky top-0 flex justify-center items-center"
      style={sticky}
    >
      {showModal && (
        <Portal id="modalInfo">
          <Modal
            position={modalPosition}
            setShowModal={setShowModal}
            description={description}
          />
        </Portal>
      )}
      <motion.div
        className="flex p-4 z-50 flex-col absolute font-sanfran font-thin text-white text-center"
        // style={textBackground}
      >
        <p className="text-2xl sm:text-3xl font-medium">{name}</p>
        <p className="text-sm sm:text-base">#{tags[tags.length - 1].name}</p>
        <div className="flex flex-row justify-center h-10 mt-3 relative">
          <motion.img
            animate={animationPlayBtn}
            onClick={e => {
              e.preventDefault();
              setShowVideo(!showVideo);
            }}
            whileHover={() => {
              triggerBackgroundAnimation('up');
            }}
            onHoverEnd={() => triggerBackgroundAnimation()}
            src={playBtn}
            className="h-8 mx-3 cursor-pointer"
            alt=""
          />
          <motion.img
            src={info}
            style={{ zIndex: 999 }}
            className="h-8 mx-3 cursor-pointer"
            onClick={e => showModalPosition(e)}
            alt=""
          />
        </div>
        {showVideo && (
          <Portal id="video">
            <Reproductor
              idVideo={idVideo}
              setShowVideo={() => setShowVideo(!showVideo)}
              showVideo={showVideo}
            />
          </Portal>
        )}
      </motion.div>
      <motion.div
        animate={animationOpacity}
        style={capaNegra}
        className="h-p100 w-full z-40"
      />
      <motion.div
        animate={animationBackground}
        style={style}
        className="flex bg-cover h-full bg-red-900 w-full absolute bg-top"
      />
    </motion.div>
  );
};

export default LandingVideo;
