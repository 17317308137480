import React from 'react';

const Persona = ({ imagen, descripcion, nombre, email, puesto, index }) => {
  const isReverse = () => {
    if (index % 2 !== 0) {
      return false;
    }
    return true;
  };

  return (
    <div
      className={
        isReverse()
          ? 'flex flex-col  w-full pb-5 h-screen items-center px-10 md:flex-row'
          : 'flex flex-col  w-full pb-5 h-screen items-center px-10 md:flex-row-reverse'
      }
    >
      <div className="flex mt-10 w-10/12 sm:w-9/12 p-0 lg:w-5/12 lg:w-6/12 h-p30 md:h-p100 lg:h-p90 md:p-5 justify-center items-center">
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="900"
          className="flex w-full h-p100"
        >
          <img src={imagen} className="object-contain" alt="" />
        </div>
      </div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-delay="200"
        data-aos-duration="1000"
        className="flex flex-col lg:justify-center w-full lg:w-5/12 h-auto  p-5"
      >
        <div className="flex flex-col">
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="100"
            data-aos-duration="1000"
            className={
              isReverse()
                ? 'flex flex-col font-sanfran text-2xl lg:text-5xl text-indexred font-bold md:-ml-10'
                : 'flex flex-col font-sanfran text-2xl lg:text-5xl text-indexred font-bold text-right justify-end md:-mr-10'
            }
          >
            {nombre}
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="200"
            data-aos-duration="1000"
            className={
              isReverse()
                ? 'flex font-sanfran text-xl text-indexred font-semibold'
                : 'flex font-sanfran text-xl text-indexred font-semibold justify-end'
            }
          >
            {puesto}
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="300"
            data-aos-duration="1000"
            className={
              isReverse()
                ? 'flex font-sanfran text-sm text-indexred font-medium'
                : 'flex font-sanfran text-sm text-indexred font-medium justify-end'
            }
          >
            {email}
          </div>
          <div
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-delay="500"
            data-aos-duration="1000"
            className={
              isReverse()
                ? 'flex mt-5 font-sanfran text-base text-gray-600 font-light'
                : 'flex mt-5 font-sanfran text-base text-gray-600 font-light text-right'
            }
          >
            {descripcion}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Persona;
