import React, { useEffect, useState, useLayoutEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PortfolioCard from './PortfolioCard';
import { gtag, GA_MEASUREMENT_ID } from '../Analitycs/Analitycs';

const variants = {
  visible: {
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.8,
      delay: 0,
    },
  },
  hidden: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
    },
  },
};

const Portfolio = ({ portfolioVideos, landingVideos }) => {
  gtag('config', GA_MEASUREMENT_ID, { page_path: '/portfolio.html' });
  const [videos, setVideos] = useState();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    setVideos(
      portfolioVideos !== undefined ? (
        <motion.div
          className="flex flex-row flex-wrap w-full justify-center "
          variants={variants}
          initial="hidden"
          animate="visible"
        >
          {landingVideos.map(video => {
            return (
              <PortfolioCard
                key={video.id}
                idVideo={video.id}
                tags={video.tags}
                name={video.name}
                description={video.description}
                thumbnail={video.pictures.sizes[4].link}
              />
            );
          })}
          {portfolioVideos.map(video => {
            return (
              <PortfolioCard
                key={video.id}
                idVideo={video.id}
                tags={video.tags}
                name={video.name}
                description={video.description}
                thumbnail={video.pictures.sizes[4].link}
              />
            );
          })}
        </motion.div>
      ) : null
    );
  }, [portfolioVideos]);

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="hidden"
        animate="show"
        className="bg-white h-full min-h-screen w-full p-header pb-4 px-4 back"
      >
        <motion.div className="flex flex-col">
          <motion.div className="flex flex-col justify-center items-center content-center p-6">
            <p className="font-sanfran font-semibold mt-4 text-4xl text-indexred text-center">
              Conoce nuestro trabajo
            </p>
            <p className="font-sanfran text-lg text-indexred font-thin text-center mb-2">
              Spots | Corporativos | Promocionales | Brand Movies | Eventos |
              Streamings
            </p>
          </motion.div>
          {videos}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Portfolio;
