import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Header from '../Header/Header';
import Home from '../Home/Home';
import fetchVideosFolder from '../../Data/Videos';
import Portfolio from '../Portfolio/Portfolio';
import Portal from '../HOC/Portal';
import Equipo from '../Equipo/Equipo';
import Servicios from '../Servicios/Servicios';
import Contacto from '../Contacto/Contacto';
import Loading from '../Loading/Loading';
import Footer from '../Footer/Footer';
import Politica from '../Footer/Politica';
import { initGA } from '../Analitycs/Analitycs';
import Cookies from '../Cookies/Cookie';
import CookiePol from '../Footer/CookiePol';
import Aviso from '../Footer/Aviso';

const App = () => {
  // const [landingVideos, setLandingVideos] = useState();
  // const [portfolioVideos, setPortfolioVideos] = useState();
  // const [topVideoLanding, setTopVideoLanding] = useState();
  // const [backPlayed, setBackPlayed] = useState(false);
  const [allVideos, setAllVideos] = useState();
  const [showPolitica, setShowPolitica] = useState(false);
  const [showAviso, setShowAviso] = useState(false);
  const [showCookiePol, setShowCookiePol] = useState(false);

  const [cookiesDisabled, setCookiesDisabled] = useState(true);
  const [showCookieModal, setShowCookieModal] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const a = await fetchVideosFolder('Landing');
      const b = await fetchVideosFolder('Portfolio');
      const c = await fetchVideosFolder('Indypicks');
      await setAllVideos({
        landing: a[0],
        portfolio: b[0],
        background: c[0],
      });
    };

    fetchData();
  }, []);

  const checkCookies = () => {
    console.log(document.cookie);
    if (document.cookie.split('=')[1] !== undefined) {
      setShowCookieModal(false);
      initGA(false);
      console.log('existe la cookie y cerramos el modal');
    } else if (document.cookie.split('=')[1] === undefined) {
      if (cookiesDisabled === true) {
        initGA(true);
        setShowCookieModal(true);
      } else if (cookiesDisabled === false) {
        initGA(false);
        setShowCookieModal(false);
      }
    }
  };

  useEffect(() => {
    checkCookies();
  }, [cookiesDisabled]);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="">
      <Loading allVideos={allVideos} />
      {allVideos !== undefined ? (
        <BrowserRouter>
          {showPolitica && <Politica setShowPolitica={setShowPolitica} />}
          {showCookiePol && <CookiePol setShowCookiePol={setShowCookiePol} />}
          {showAviso && <Aviso setShowAviso={setShowAviso} />}
          {showCookieModal && (
            <Cookies
              setCookiesDisabled={setCookiesDisabled}
              showCookieModal={setShowCookieModal}
            />
          )}
          <Portal id="MHeader">
            <Header />
          </Portal>

          <Switch>
            <Route
              exact
              path="/portfolio"
              component={() => (
                <Portfolio
                  portfolioVideos={allVideos.portfolio}
                  landingVideos={allVideos.landing}
                />
              )}
            />
            <Route
              exact
              path="/"
              component={() => (
                <Home
                  landingVideos={allVideos.landing}
                  topVideoLanding={allVideos.background}
                  setCookiesDisabled={setCookiesDisabled}
                />
              )}
            />
            <Route exact path="/equipo" component={() => <Equipo />} />
            <Route exact path="/produccion" component={() => <Servicios />} />
            <Route exact path="/contacto" component={() => <Contacto />} />
          </Switch>
          <Footer
            setShowPolitica={setShowPolitica}
            setShowAviso={setShowAviso}
            setShowCookiePol={setShowCookiePol}
          />
        </BrowserRouter>
      ) : null}
    </div>
  );
};

export default App;
