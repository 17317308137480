import React from 'react';
import { motion } from 'framer-motion';

const MiniCard = ({ titulo, descripcion }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 300,
        transition: {
          type: 'spring',
          duration: 0.5,
          damping: 100,
          stiffness: 200,
        },
      }}
      animate={{
        opacity: 1,
        y: 0,
        transition: {
          type: 'spring',
          duration: 0.5,
          damping: 100,
          stiffness: 200,
        },
      }}
      exit={{
        opacity: 0,
        y: -300,
        transition: {
          type: 'spring',
          duration: 0.5,
          damping: 100,
          stiffness: 200,
        },
      }}
      style={{ boxShadow: '0px 6px 20px rgba(213, 0, 55, 0.11)' }}
      className="flex flex-col bg-white p-4 rounded-lg w-full h-full"
    >
      <div className="flex">
        <p className="font-sanfran text-indexred font-semibold text-base mb-2">
          {titulo}
        </p>
      </div>
      <div className="flex">
        <p className="font-sanfran text-gray-500 font-light text-sm">
          {descripcion}
        </p>
      </div>
    </motion.div>
  );
};

export default MiniCard;
