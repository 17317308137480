const Realizacion = {
  RealizacionYStreaming: [
    {
      Titulo: 'Configuración adecuada para todo tipo de proyectos',
      Descripcion:
        'Tenemos experiencia en realizaciones de todo tipo: ponencias, jornadas, seminarios, convenciones, presentación de productos, formación, teatro, conciertos, galas, demostraciones, deportes...',
    },
    {
      Titulo: 'Emisión en Streaming',
      Descripcion:
        'Posibilidad de emitir en directo desde YouTube, Facebook Live, Periscope, Twice... con máxima fiabilidad y resolución 1080p.',
    },
    {
      Titulo: 'Realización de eventos a 2, 3 o más cámaras',
      Descripcion:
        'Nuestro equipo técnico nos permite llevar a cabo una realización multicámara para cubrir cualquier necesidad que requiera el proyecto.',
    },
    {
      Titulo: 'Emisión en pantallas de sala',
      Descripcion:
        'Contamos con la posibilidad de insertar en pantalla cualquier tipo de gráficas, cartelas, vídeos, etc.',
    },
    {
      Titulo: 'Inserción de la señal del ordenador del ponente',
      Descripcion:
        'Ofrecemos la posibilidad de integrar la señal de la presentación del ponente en la realización o el streaming.',
    },
    {
      Titulo: 'Realización y streaming por hardware',
      Descripcion:
        'Garantizamos la realización a tiempo real sin  retardo ni cuelgues de software.',
    },
    {
      Titulo: 'Grabación de realización',
      Descripcion:
        'Además de la realización, la grabación individual de fuentes nos permite disponer de su señal para posteriores ediciones.',
    },
    {
      Titulo: 'Completo equipamiento para realización',
      Descripcion:
        'Trabajamos con equipos de marcas referentes: Blackmagic, Sony, Canon, Atoms, Paralinx, Rode...',
    },
    {
      Titulo: 'Producción de vídeo para eventos',
      Descripcion:
        'Desde los vídeos que se lanzan en un evento hasta un video resumen del propio evento.',
    },
  ],
};

export default Realizacion;
