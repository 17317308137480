import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import Player from '@vimeo/player';
import { motion } from 'framer-motion';
import lottie from 'lottie-web';
import logoIndex from '../../../assets/Icons/mubis.svg';
import vimeo from '../../../assets/logos/vimeo_1.svg';
import instagram from '../../../assets/logos/instagram.svg';
import facebook from '../../../assets/logos/facebook.svg';
import phone from '../../../assets/Icons/phonegood.svg';
import mail from '../../../assets/Icons/mail_1.svg';
import animationData from '../../../assets/animatedIcons/eskrol.json';

const RandomVideo = ({ topVideoLanding }) => {
  const wrapperDos = useRef(null);
  const animBox = useRef(null);
  let animObj = null;

  useEffect(() => {
    animObj = lottie.loadAnimation({
      container: animBox.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  const selectRandomVideo = () => {
    return topVideoLanding[Math.floor(Math.random() * topVideoLanding.length)];
  };

  const capaNegra = {
    backgroundColor: `rgba(0,0,0,0.22)`,
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  };

  const createPlayer = video => {
    const vidPlayerLanding = new Player(wrapperDos.current, {
      id: video.id,
      autoplay: true,
      loop: true,
      controls: false,
      muted: true,
      autopause: false,
      quality: '1080p',
    });
    return vidPlayerLanding;
  };

  useEffect(() => {
    if (topVideoLanding !== undefined) {
      const video = selectRandomVideo();
      const vidPlayerLanding = createPlayer(video);
      vidPlayerLanding.on('loaded', () => {
        const iframe = wrapperDos.current.childNodes[1];
        iframe.classList.add('backgrounding');
      });

      vidPlayerLanding.on('ended', () => {
        const nextVid = selectRandomVideo();
        vidPlayerLanding.loadVideo(nextVid.id);
      });
    }
  }, []);

  return (
    <>
      <div
        ref={wrapperDos}
        id="wrapperDos"
        className="p-0 w-full bg-gray-400 h-screen overflow-hidden relative"
      >
        <div
          style={capaNegra}
          className="h-screen w-full flex justify-center items-center relative"
        >
          <img src={logoIndex} className="w-2/12 hidden md:flex" alt="" />
          <div className="flex flex-col justify-center items-center content-center z-50 absolute w-5 md:w-8  right-0 mr-5 md:mr-10 fixed">
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.instagram.com/index.video/?hl=es"
            >
              <img
                src={instagram}
                alt="instagram"
                className="h-6 my-2 md:my-4"
              />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://www.facebook.com/index.audiovisual/"
            >
              <img src={facebook} alt="facebook" className="h-6 my-2 md:my-4" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://vimeo.com/sobreindex"
            >
              <img src={vimeo} alt="vimeo" className="h-6 my-2 md:my-4" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="https://vimeo.com/sobreindex"
            >
              <Link to="/contacto">
                <img src={phone} alt="vimeo" className=" my-2 h-6 md:my-4" />
              </Link>
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.2 }}
              href="mailto:contacto@indexvideo.es"
            >
              <img src={mail} alt="vimeo" className="h-4 my-2 md:my-4" />
            </motion.a>
          </div>
          {/* <p className="text-xs font-sanfran text-white font-thin mx-auto absolute bottom-0 mb-20">
            Scroll down
          </p> */}
          <div
            ref={animBox}
            className="flex w-24  absolute bottom-0 mb-4 mx-auto"
          />
        </div>
      </div>
    </>
  );
};

export default RandomVideo;
