import React from 'react';
import closer from '../../../assets/Icons/Close.svg';

const Politica = ({ setShowPolitica }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full py-8 md:p-header fixed z-20 ">
      <div className="flex flex-col h-full w-full relative bg-white rounded-lg p-8 pt-4 pr-0 z-20 m-5">
        <div
          style={{ height: '1.25rem' }}
          className="flex flex-row w-full items-center justify-end pr-10 my-3"
        >
          <img
            onClick={() => setShowPolitica(false)}
            src={closer}
            className="h-5 w-5 cursor-pointer"
            alt=""
          />
        </div>
        <div className="flex flex-col rounded-lg bg-white overflow-y-scroll mr-8">
          <p>
            RESPONSABLE DEL TRATAMIENTO El Responsable del Tratamiento es INDEX
            PRODUCCIONES, S.L., Font de la Uxola 4, 03803, Alcoy (ALICANTE).
            Principios de privacidad Desde INDEX PRODUCCIONES, S.L. nos
            comprometemos contigo a trabajar continuamente para garantizar la
            privacidad en el tratamiento de tus datos personales, y para
            ofrecerte en cada momento la información más completa y clara que
            podamos. Te animamos a leer detenidamente esta sección antes de
            facilitarnos tus datos personales. Si eres menor de catorce años te
            rogamos que no nos facilites tus datos sin consentimiento de tus
            padres. En este apartado te informamos de cómo tratamos los datos de
            las personas que tienen relación con nuestra organización. Empezando
            por nuestros principios: - No solicitamos información personal, a no
            ser que sea necesaria para prestarte los servicios que nos
            requieras. - Nunca compartimos información personal con nadie,
            excepto para cumplir la ley, o contemos con tu autorización expresa.
            - Nunca utilizaremos tus datos personales para finalidades distintas
            a las expresadas en la presente política de privacidad. - Tus datos
            siempre serán tratados con un nivel de protección adecuado a la
            legislación en materia de protección de datos, y no los someteremos
            a decisiones automatizadas. La presente política de privacidad la
            hemos redactado teniendo en cuenta las exigencias de la actual
            legislación de protección de datos: - Reglamento (UE) 2016/679 del
            Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a
            la protección de las personas físicas (RGPD). - Ley Orgánica 3/2018,
            de 5 de diciembre, de Protección de Datos de Carácter Personal y
            garantía de los derechos digitales (LOPD). - Real Decreto 1720/2007,
            de 21 de diciembre (RLOPD). Esta política de privacidad está
            redactada con fecha 6 de diciembre de 2018. Con motivo de la
            modificación de criterios de tratamiento, en aras de facilitar su
            comprensión o de adaptarla a la legalidad vigente, es posible que
            modifiquemos la presente política de privacidad. Actualizaremos la
            fecha de la misma, para que puedas comprobar su vigencia.
            Tratamientos que realizamos TRATAMIENTO DE PROVEEDORES Base
            Jurídica: RGPD: 6.1.b) Tratamiento necesario para la ejecución de un
            contrato en el que el interesado es parte o para la aplicación a
            petición de éste de medidas precontractuales. RGPD: 6.1.c)
            Tratamiento necesario para el cumplimiento de una obligación legal
            aplicable al responsable del tratamiento. Real Decreto Legislativo
            2/2015, de 23 de octubre, por el que se aprueba el texto refundido
            de la Ley del Estatuto de los Trabajadores. Ley 58/2003, de 17 de
            diciembre, General Tributaria. Fines del Tratamiento: - Adquisición
            de productos y/o servicios que necesitamos para el desarrollo de
            nuestra actividad. - Control de los subcontratistas si procede.
            Colectivo: - Proveedores. - Trabajadores de nuestros proveedores.
            Categorías de Datos: - Nombre y apellidos, DNI/NIF/Documento
            identificativo, dirección, firma y teléfono. - Datos de detalle de
            empleo: puesto de trabajo. Formación en seguridad laboral. - Datos
            económico financieros y de seguros: Datos bancarios Categorías de
            Destinatarios: - Entidades financieras. (Pago de facturas) - Agencia
            Estatal de Administración Tributaria. Transferencias
            Internacionales: No están previstas transferencias internacionales
            de los datos. Plazo de Supresión: Se conservarán durante el tiempo
            necesario para cumplir con la finalidad para la que se recabaron y
            para determinar las posibles responsabilidades que se pudieran
            derivar de dicha finalidad y del tratamiento de los datos, conforme
            a la Ley 58/2003, de 17 de diciembre, General Tributaria, Medidas de
            Seguridad: Adaptadas a las exigencias del Reglamento (EU) 2016/679,
            Reglamento General de Protección de Datos. TRATAMIENTO DE CLIENTES.
            Base Jurídica: RGPD: 6.1.a) El interesado dio su consentimiento para
            el tratamiento de sus datos personales para uno o varios fines
            específicos. RGPD: 6.1.b) Tratamiento necesario para la ejecución de
            un contrato en el que el interesado es parte o para la aplicación a
            petición de éste de medidas precontractuales. RGPD: 6.1.c)
            Tratamiento necesario para el cumplimiento de una obligación legal
            aplicable al responsable del tratamiento. Real Decreto Legislativo
            2/2015, de 23 de octubre, por el que se aprueba el texto refundido
            de la Ley del Estatuto de los Trabajadores. Ley 58/2003, de 17 de
            diciembre, General Tributaria. Fines del Tratamiento: Suministro de
            nuestros productos / servicios Colectivo: Clientes Categorías de
            Datos: - Nombre y apellidos, DNI/NIF/Documento identificativo,
            dirección, firma y teléfono. - Datos económico financieros y de
            seguros: Datos bancarios Categorías de Destinatarios: - Entidades
            financieras. - Agencia Estatal de Administración Tributaria.
            Transferencias Internacionales: No están previstas transferencias
            internacionales de los datos. Plazo de Supresión: Se conservarán
            durante el tiempo necesario para cumplir con la finalidad para la
            que se recabaron y para determinar las posibles responsabilidades
            que se pudieran derivar de dicha finalidad y del tratamiento de los
            datos, conforme a la Ley 58/2003, de 17 de diciembre, General
            Tributaria, Medidas de Seguridad: Adaptadas a las exigencias del
            Reglamento (EU) 2016/679, Reglamento General de Protección de Datos.
            TRATAMIENTO DE CONTACTOS Base Jurídica: Consentimiento del
            interesado Fines del Tratamiento: Atender su solicitud, enviarle
            información y realizar un seguimiento de la solicitud. Colectivo:
            Personas de contacto, clientes, proveedores Categorías de Datos:
            Nombre y apellidos, teléfono, dirección de email Categorías de
            Destinatarios: No se contemplan cesiones de datos a terceros.
            Transferencias Internacionales: No están previstas transferencias
            internacionales de los datos. Plazo de Supresión: Los datos de
            contacto se conservarán por un periodo indefinido, o hasta que el
            interesado solicite su supresión. Medidas de Seguridad: Adaptadas a
            las exigencias del Reglamento (EU) 2016/679, Reglamento General de
            Protección de Datos. TRATAMIENTO DE CANDIDATOS PROCESOS DE SELECCIÓN
            (RRHH) Base Jurídica: RGPD: 6.1.b) Tratamiento necesario para la
            ejecución de un contrato en el que el interesado es parte o para la
            aplicación a petición de este de medidas precontractuales. Fines del
            Tratamiento: Selección de personal y provisión de puestos de
            trabajo. Colectivo: Candidatos presentados a procedimientos de
            provisión de puestos de trabajo. Categorías de Datos: Nombre y
            apellidos, DNI/CIF/Documento identificativo, número de registro de
            personal, dirección, firma y teléfono. Categorías especiales de
            datos: datos de salud (discapacidades). Datos de características
            personales: Sexo, estado civil, nacionalidad, edad, fecha y lugar de
            nacimiento y datos familiares. Datos académicos y profesionales:
            Titulaciones, formación y experiencia profesional. Datos de detalle
            de empleo. Categorías de Destinatarios: No están previstas cesiones
            de datos a terceros. Transferencias Internacionales: No están
            previstas transferencias internacionales de los datos. Plazo de
            Supresión: Se conservarán durante el tiempo necesario para cumplir
            con la finalidad para la que se recabaron y para determinar las
            posibles responsabilidades que se pudieran derivar de dicha
            finalidad y del tratamiento de los datos. Medidas de Seguridad:
            Adaptadas a las exigencias del Reglamento (EU) 2016/679, Reglamento
            General de Protección de Datos. TRATAMIENTO DE EMPLEADOS Base
            Jurídica: RGPD: 6.1.b) Tratamiento necesario para la ejecución de un
            contrato en el que el interesado es parte o para la aplicación a
            petición de éste de medidas precontractuales. RGPD: 6.1.c)
            Tratamiento necesario para el cumplimiento de una obligación legal
            aplicable al responsable del tratamiento. Real Decreto Legislativo
            2/2015, de 23 de octubre, por el que se aprueba el texto refundido
            de la Ley del Estatuto de los Trabajadores. Fines del Tratamiento: -
            Gestión de personal contratado. - Expediente personal. Control
            horario. Formación. Planes de pensiones. Prevención de riesgos
            laborales. - Emisión de la nómina del personal. - Gestión de la
            actividad sindical. Colectivo: Empleados Categorías de Datos: Nombre
            y apellidos, DNI/CIF/Documento identificativo, número de registro de
            personal, número de Seguridad Social/Mutualidad, dirección, firma y
            teléfono. Categorías especiales de datos: datos de salud (bajas por
            enfermedad, accidentes laborales y grado de discapacidad, sin
            inclusión de diagnósticos), afiliación sindical, a los exclusivos
            efectos del pago de cuotas sindicales (en su caso), representante
            sindical (en su caso), justificantes de asistencia de propios y de
            terceros. Datos de características personales: Sexo, estado civil,
            nacionalidad, edad, fecha y lugar de nacimiento y datos familiares.
            Datos de circunstancias familiares: Fecha de alta y baja, licencias,
            permisos y autorizaciones. Datos académicos y profesionales:
            Titulaciones, formación y experiencia profesional. Datos de detalle
            de empleo y carrera administrativa. Incompatibilidades. Datos de
            control de presencia: fecha/hora entrada y salida, motivo de
            ausencia. Datos económico-financieros: Datos económicos de nómina,
            créditos, préstamos, avales, deducciones impositivas baja de haberes
            correspondiente al puesto de trabajo anterior (en su caso),
            retenciones judiciales (en su caso), otras retenciones (en su caso).
            Datos bancarios. Categorías de Destinatarios: - Entidad a quien se
            encomiende la gestión en materia de riesgos laborales. - Tesorería
            General de la Seguridad Social. - Organizaciones sindicales. -
            Entidades financieras. - Agencia Estatal de Administración
            Tributaria. - Contratistas principales a los que prestemos servicios
            como subcontratistas. Transferencias Internacionales: No están
            previstas transferencias internacionales de los datos. Plazo de
            Supresión: Se conservarán durante el tiempo necesario para cumplir
            con la finalidad para la que se recabaron y para determinar las
            posibles responsabilidades que se pudieran derivar de dicha
            finalidad y del tratamiento de los datos. Los datos económicos de
            esta actividad de tratamiento se conservarán al amparo de lo
            dispuesto en la Ley 58/2003, de 17 de diciembre, General Tributaria.
            Medidas de Seguridad: Adaptadas a las exigencias del Reglamento (EU)
            2016/679, Reglamento General de Protección de Datos. TUS DERECHOS
            Tienes derecho a solicitarnos una copia de tus datos personales, a
            rectificar los datos inexactos o completarlos si estuvieren
            incompletos, o en su caso suprimirlos, cuando ya no sean necesarios
            para los fines para los que fueron recogidos. También tienes derecho
            a limitar el tratamiento de tus datos personales y a obtener tus
            datos personales en un formato estructurado y legible. Puedes
            oponerte al tratamiento de tus datos personales en algunas
            circunstancias (en particular, cuando no tengamos que procesarlos
            para cumplir con un requisito contractual u otro requisito legal, o
            cuando el objeto del tratamiento sea el marketing directo). Cuando
            nos hayas otorgado tu consentimiento, podrás retirarlo en cualquier
            momento. En ese momento dejaremos de tratar tus datos o, en su caso,
            dejaremos de hacerlo para esa finalidad en concreto. Si decides
            retirar tu consentimiento, esto no afectará a ningún tratamiento que
            haya tenido lugar mientras tu consentimiento estaba vigente. Estos
            derechos podrán verse limitados; por ejemplo si para cumplir tu
            solicitud tuviésemos que revelar datos sobre otra persona, o si nos
            solicitas que eliminemos algunos registros que estamos obligados a
            mantener por una obligación legal o por un interés legítimo, como
            pueda ser el ejercicio de defensa ante reclamaciones. O incluso en
            aquellos casos donde debe prevalecer el derecho a la libertad de
            expresión e información. Puedes contactar con nosotros por
            cualquiera de los medios indicados en el apartado Responsable del
            Tratamiento de esta política de privacidad, aportando una copia de
            un documento que acredite tu identidad (normalmente el DNI). Otro de
            tus derechos es el de no ser objeto de una decisión basada
            únicamente en un tratamiento automatizado, incluida la elaboración
            de perfiles que produzca efectos jurídicos o te afecte. Frente a
            cualquier vulneración de tus derechos, como, por ejemplo, que no
            hayamos atendido tu solicitud, tienes derecho a presentar una
            reclamación ante la Autoridad de Control en materia de protección de
            datos. Esta puede ser la de tu país (si vives fuera de España) o la
            Agencia Española de Protección de Datos (si vives en España).
            Información adicional Tratamiento de tus datos fuera del Espacio
            Económico Europeo. Para los tratamientos indicados podemos utilizar
            servicio de los siguientes proveedores ajenos al Espacio Económico
            Europeo, pero acogidos al acuerdo de Privacy Shield, aprobado por
            las autoridades de protección de datos de la Unión Europea. Apple
            iOS: Más información:
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZjAAK">
              Pincha aqui
            </a>
            Facebook/ Instagram (FB Messenger): Más información: monday.com:
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZjAAK">
              Pincha aqui
            </a>
            Colaboración equipos de trabajo Más información:
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZjAAK">
              Pincha aqui
            </a>
            Whatsapp: Mensajería instantánea móvil Más información:
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG">
              Pincha aqui
            </a>
            Enlaces a sitios web de terceros. Nuestro sitio web puede, en
            algunas ocasiones, contener enlaces hacia otros sitios web. Es tu
            responsabilidad asegurarte de leer la política de protección de
            datos y las condiciones legales que se apliquen a cada sitio. Datos
            de terceros. Si nos facilitas datos de terceros, asumes la
            responsabilidad de informarles previamente según lo establecido en
            el artículo 14 del RGPD.
          </p>
        </div>
      </div>
      <div className="w-full h-full bg-black opacity-25 absolute z-10" />
    </div>
  );
};

export default Politica;
