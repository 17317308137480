import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Portal from '../HOC/Portal';
import Reproductor from '../Player/Reproductor';
import PlayBtn from '../../../assets/Icons/Playbttn.svg';
import Info from '../../../assets/Icons/infoicon.svg';
import Indi from '../../../assets/logos/indinegro.svg';

const PortfolioCard = ({ thumbnail, name, idVideo, description, tags }) => {
  const [showVideo, setShowVideo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const style = {
    backgroundImage: `url(${thumbnail}})`,
    backgroundSize: '140%',
  };

  const variants = {
    visible: { opacity: 1, y: 0, type: 'tween', stiffness: 100, damping: 1000 },
    hidden: {
      opacity: 0,
      y: 50,
      // backgroundSize: '130%',
      type: 'tween',
      stiffness: 100,
      damping: 1000,
    },
  };

  return (
    <motion.div
      whileHover={{
        scale: 1.035,
        backgroundSize: '150%',
        transition: { type: 'spring', damping: 50, stiffness: 600 },
      }}
      variants={variants}
      style={style}
      className="relative cursor-pointer bg-cover  overflow-hidden w-portfolioMiniature2 h-56 bg-no-repeat  rounded-md bg-center flex justify-center items-center content-center m-3"
    >
      {showInfo && (
        <motion.div
          initial={{ y: -300 }}
          animate={{
            y: 0,
            transition: { type: 'spring', stiffness: 100, damping: 200 },
          }}
          exit={{
            y: 300,
            transition: { type: 'spring', stiffness: 100, damping: 200 },
          }}
          onClick={() => setShowInfo(!showInfo)}
          className="flex flex-col justify-center h-full bg-indexred p-8 pt-8 whitespace-pre-line relative overflow-hidden rounded-md"
        >
          <img
            src={Indi}
            style={{ bottom: '-2.5rem', left: '-0.5rem', opacity: 0.063 }}
            className=" h-64 absolute "
            alt=""
          />
          <p className="font-sanfran font-thin text-sm px-2 text-white">
            {description}
          </p>
        </motion.div>
      )}

      <motion.div
        whileHover={{ scale: 1.3 }}
        className="flex absolute top-0 left-0 p-3 z-10"
        onClick={() => setShowInfo(!showInfo)}
      >
        <img src={Info} alt="" className="h-5" />
      </motion.div>

      {!showInfo && (
        <motion.div
          onClick={() => setShowVideo(!showVideo)}
          style={{ zIndex: 5 }}
          className=" opacity-0 w-full h-full flex justify-center items-center "
          whileHover={{
            opacity: 1,
            scale: 1.2,
            transition: {
              type: 'spring',
              damping: 1000,
              stiffness: 1000,
              duration: 0.1,
            },
          }}
        >
          <img src={PlayBtn} alt="" className="h-8 mx-2 " />
        </motion.div>
      )}

      {!showInfo && (
        <motion.div className="flex flex-col gradientedos w-full h-32 px-5 absolute bottom-0 align-baseline">
          <div className="absolute bottom-0 left-0 m-3">
            <p className="flex flex-row items-center text-white font-sanfran font-normal text-base">
              {name}
            </p>
            <p className="flex flex-row items-center text-center text-white font-sanfran font-light text-xs">
              #{tags[tags.length - 1].name}
            </p>
          </div>
        </motion.div>
      )}
      {showVideo && (
        <Portal id="video">
          <Reproductor
            idVideo={idVideo}
            setShowVideo={() => setShowVideo(!showVideo)}
          />
        </Portal>
      )}
    </motion.div>
  );
};

export default PortfolioCard;
