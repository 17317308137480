import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link, Navlink } from 'react-router-dom';
import indexInvertedLogo from '../../../assets/logos/InvertedIndexLogo_1.svg';

const parent = {
  hidden: {
    height: 0,
    transition: {
      duration: 0.9,
      type: 'spring',
      stiffness: 100,
      damping: 100,
      delay: 0.7,
    },
  },
  show: {
    height: '100vh',
    transition: {
      duration: 0.5,
      type: 'spring',
      stiffness: 100,
      damping: 100,
    },
  },
};

const container = {
  hidden: {
    opacity: 0,
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
      delay: 0.5,
      duration: 0.5,
    },
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.12,
    },
  },
};

const logo = {
  hidden: {
    opacity: 0,
    x: -30,
    transition: { ease: 'easeInOut', duration: 0.2, delay: 0.3 },
  },

  show: {
    opacity: 1,
    x: 0,
    transition: { ease: 'easeInOut', duration: 0.8, delay: 0.3 },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: -30,
    transition: { ease: 'easeInOut', duration: 0.4 },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeInOut', duration: 0.3 },
  },
};

const vara = {
  hidden: {
    opacity: 0,
    y: -90,
    transition: { ease: 'easeInOut', delay: 0.3, duration: 0.4 },
  },
  show: {
    opacity: 1,
    y: 0,
    transition: { ease: 'easeInOut', delay: 0.2, duration: 0.4 },
  },
};

const Menu = ({ showMenu, setShowMenu }) => {
  const links = (
    <motion.nav
      variants={container}
      animate="show"
      exit="hidden"
      className="flex flex-col ml-5   "
    >
      <motion.div
        whileTap={{ scale: 1.1 }}
        whileHover={{
          marginLeft: '35px',
          transition: { duration: 0.2, type: 'spring', damping: 200 },
        }}
        variants={item}
        className="my-2 ml-5  lg:my-5"
      >
        <Link
          onClick={() => setShowMenu()}
          className="text-white font-sanfran text-3xl lg:text-5xl font-thin "
          to="/"
        >
          Inicio
        </Link>
      </motion.div>
      <motion.div
        whileTap={{ scale: 1.1 }}
        whileHover={{
          marginLeft: '35px',
          transition: { duration: 0.2, type: 'spring', damping: 200 },
        }}
        variants={item}
        className="my-2 ml-5  lg:my-5"
      >
        <Link
          onClick={() => setShowMenu()}
          className="text-white font-sanfran text-3xl lg:text-5xl font-thin "
          to="/portfolio"
        >
          Portfolio
        </Link>
      </motion.div>
      <motion.div
        whileTap={{ scale: 1.1 }}
        whileHover={{
          marginLeft: '35px',
          transition: { duration: 0.2, type: 'spring', damping: 200 },
        }}
        variants={item}
        className="my-2 ml-5  lg:my-5"
      >
        <Link
          onClick={() => setShowMenu()}
          className="text-white font-sanfran text-3xl lg:text-5xl font-thin "
          to="/produccion"
        >
          Producción
        </Link>
      </motion.div>
      <motion.div
        whileTap={{ scale: 1.1 }}
        whileHover={{
          marginLeft: '35px',
          transition: { duration: 0.2, type: 'spring', damping: 200 },
        }}
        variants={item}
        className="my-2 ml-5  lg:my-5"
      >
        <Link
          onClick={() => setShowMenu()}
          className="text-white font-sanfran text-3xl lg:text-5xl font-thin "
          to="/equipo"
        >
          Equipo
        </Link>
      </motion.div>

      <motion.div
        whileTap={{ scale: 1.1 }}
        whileHover={{
          marginLeft: '35px',
          transition: { duration: 0.2, type: 'spring', damping: 200 },
        }}
        variants={item}
        className="my-2 ml-5  lg:my-5"
      >
        <Link
          onClick={() => setShowMenu()}
          className="text-white font-sanfran text-3xl lg:text-5xl font-thin "
          to="/contacto"
        >
          Contacto
        </Link>
      </motion.div>
    </motion.nav>
  );

  return (
    <AnimatePresence>
      {showMenu && (
        <motion.div
          className=" flex flex-col m-auto justify-center items-center h-p100 w-full fixed bg-indexred z-20"
          variants={parent}
          initial="hidden"
          animate="show"
          exit="hidden"
          transition={{
            duration: 0.5,
            type: 'spring',
            stiffness: 100,
            damping: 100,
          }}
        >
          <motion.div
            className="flex flex-row w-11/12  h-p85 items-center"
            variants={container}
            animate="show"
            exit="hidden"
          >
            <motion.img
              variants={logo}
              animate="show"
              exit="hidden"
              className="flex justify-center items-center my-auto mx-8 sm:mx-10 h-p35 md:h-p50 "
              src={indexInvertedLogo}
              alt=""
            />

            <motion.div
              variants={vara}
              animate="show"
              exit="hidden"
              className="bg-white h-p100 w-line"
            />
            {links}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Menu;
