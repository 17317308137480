import React, { useLayoutEffect } from 'react';
import { motion } from 'framer-motion';
import vimeo from '../../../assets/logos/vimeo_1.svg';
import instagram from '../../../assets/logos/instagram.svg';
import facebook from '../../../assets/logos/facebook.svg';
import Indi from '../../../assets/logos/indirojocortado.svg';
import { gtag, GA_MEASUREMENT_ID } from '../Analitycs/Analitycs';

const set = 0;

export const Contacto = () => {
  gtag('config', GA_MEASUREMENT_ID, { page_path: '/contacto.html' });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-row h-screen  w-full back p-headerMobile sm:p-header pb-0 px-0 sm:px-0 sm:pb-0">
      {/* //Parte del indi y el oye */}
      <div className="hidden md:flex flex-col justify-between w-5/12">
        <span className="text-indexred font-sanfran font-semibold text-5xl text-right mr-2 mt-5">
          Oye,
        </span>
        <img src={Indi} alt="" className="-mb-1 -mr-1" />
      </div>

      <div className="flex flex-col justify-center w-full md:w-7/12 bg-indexred h-full px-4">
        <span className="hidden md:flex text-white font-sanfran font-semibold text-5xl text-center md:text-left mt-5">
          tenemos que hablar...
        </span>
        <span className="md:hidden text-white font-sanfran font-semibold text-5xl text-center md:text-right ml-2 mt-5">
          Tenemos que hablar...
        </span>
        <span className=" text-white font-sanfran font-semibold text-2xl text-center md:text-left ml-2 mt-1">
          Si quieres claro.
        </span>
        {/* Div envoltorio de informacion  */}
        <div className="flex flex-col justify-start  w-full h-full items-center p-5 mb-4">
          {/* Div wrapper of each piece of information */}
          <div className="flex flex-col w-full p-2 mb-5">
            <p className="font-sanfran text-white text-lg font-bold">Correo</p>
            <div className="flex h-line w-full bg-white mb-2 mt-1" />
            <motion.p
              whileHover={{ scale: 1.02 }}
              className="font-sanfran text-white text-base font-light cursor-pointer"
            >
              <a href="mailto:contacto@indexvideo.es">contacto@indexvideo.es</a>
            </motion.p>
          </div>
          {/* KC */}
          <div className="flex flex-col w-full p-2 mb-5">
            <p className="font-sanfran text-white text-lg font-bold">
              Teléfonos
            </p>
            <div className="flex h-line w-full bg-white mb-2 mt-1" />
            <p className="font-sanfran text-white text-base font-light">
              965 522 675 - 670 638 921
            </p>
          </div>
          {/* KC */}
          <div className="flex flex-col w-full p-2 mb-5">
            <p className="font-sanfran text-white text-lg font-bold">
              Dirección
            </p>
            <div className="flex h-line w-full bg-white mb-2 mt-1" />
            <p className="font-sanfran text-white text-base font-light">
              <motion.p
                whileHover={{ scale: 1.02 }}
                className="font-sanfran text-white text-base font-light cursor-pointer"
              >
                <a href="https://goo.gl/maps/RkYYu1FSjwXwVwzU6">
                  Font de la Uxola, 4. 03803 Alcoy, Alicante
                </a>
              </motion.p>
            </p>
          </div>
          {/* KC */}
          <div className="flex flex-col w-full p-2 mb-5">
            <p className="font-sanfran text-white text-lg font-bold">
              Síguenos
            </p>
            <div className="flex h-line w-full bg-white mb-2 mt-1" />
            <p className="font-sanfran text-white text-base font-light">
              <div className="flex flex-row w-full justify-start my-1">
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  href="https://www.instagram.com/index.video/?hl=es"
                >
                  <img src={instagram} alt="instagram" className="h-6 mr-4" />
                </motion.a>
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  href="https://www.facebook.com/index.audiovisual/"
                >
                  <img src={facebook} alt="facebook" className="h-6 mx-4" />
                </motion.a>
                <motion.a
                  whileHover={{ scale: 1.2 }}
                  href="https://vimeo.com/sobreindex"
                >
                  <img src={vimeo} alt="vimeo" className="h-6 mx-4" />
                </motion.a>
              </div>
            </p>
          </div>
          {/* KC */}
        </div>
      </div>
    </div>
  );
};

export default Contacto;
