import React, { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';
import { motion, useAnimation } from 'framer-motion';
import { useLocation } from 'react-router-dom';

import animationData from '../../../assets/animatedIcons/indifinalanimation.json';

let animObj = null;

const Loading = ({ allVideos }) => {
  const animBox = useRef(null);
  const topAnimation = useAnimation();
  const indiAnimation = useAnimation();
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    animObj = lottie.loadAnimation({
      container: animBox.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  }, []);

  useEffect(() => {
    if (allVideos !== undefined) {
      topAnimation
        .start({
          height: 0,
          transition: {
            duration: 5,
            delay: 1.5,
            type: 'spring',
            stiffness: 100,
            damping: 100,
          },
        })
        .then(() => setRendered(true));
      indiAnimation.start({
        opacity: 0,
        transition: { duration: 0.4, delay: 1.5 },
      });
    }
  }, [allVideos]);

  return (
    <>
      {rendered === false ? (
        <motion.div
          key="top"
          animate={topAnimation}
          className="flex flex-col bg-indexred justify-center items-center h-screen w-full fixed"
          style={{ zIndex: 999 }}
        >
          <motion.div
            animate={indiAnimation}
            ref={animBox}
            className="flex h-24 w-24"
          />
        </motion.div>
      ) : null}
    </>
  );
};

export default Loading;
