import React, { useLayoutEffect } from 'react';
import RandomVideo from '../RandomVideo/RandomVideo';
import LandingVideo from '../LandingVideo/LandingVideo';
import Footer from '../Footer/Footer';
import { gtag, GA_MEASUREMENT_ID } from '../Analitycs/Analitycs';

const Home = ({ landingVideos, topVideoLanding }) => {
  gtag('config', GA_MEASUREMENT_ID, { page_path: '/' });

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <RandomVideo topVideoLanding={topVideoLanding} />

      {landingVideos !== undefined ? (
        landingVideos.map((video, i) => {
          return (
            <LandingVideo
              key={i}
              thumbnail={video.pictures.sizes[5].link}
              name={video.name}
              idVideo={video.id}
              description={video.description}
              tags={video.tags}
            />
          );
        })
      ) : (
        <div className="bg-gray-400 h-screen w-full flex justify-center items-center">
          No se han podido cargar videos
        </div>
      )}
    </div>
  );
};

export default Home;
