const ServiciosAudiovisuales = {
  ServiciosAud: [
    {
      Titulo: 'Servicio de cámara',
      Descripcion:
        'Disponemos el servicio de cámara con operador, ENG o equipo para publicidad.',
    },
    {
      Titulo: 'Servicio de producción',
      Descripcion:
        'Nuestro servicio y agenda de producción para que hagas el mejor rodaje posible.',
    },
    {
      Titulo: 'Equipo completo de rodaje',
      Descripcion:
        'Para aquellos profesionales que necesiten un equipo humano y técnico para un rodaje de garantía.',
    },
    {
      Titulo: 'Postproducción',
      Descripcion:
        'Si dispones de un material rodado y necesitas un estudio, tenemos sala de edición, sonorización y etalonaje.',
    },
    {
      Titulo: 'Estudio de sonido',
      Descripcion:
        'Nuestro estudio de sonido incluye una pequeña sala insonorizada para locuciones y pequeños instrumentos.',
    },
    {
      Titulo: 'Alquiler de iluminación',
      Descripcion:
        'Tenemos pantallas LED de alto CRI, focos fresnel, reflectores, soportes y bastidores.',
    },
    {
      Titulo: 'Grafismo / motion graphics / 3D',
      Descripcion:
        'Dinos qué necesitas y pondremos a tu disposición las calidades infográficas de nuestros trabajos.',
    },
    {
      Titulo: 'Production office in place',
      Descripcion:
        'Eres una productora que viene a rodar a Alicante y necesitas alquilar una oficina. INDEX es tu casa.',
    },
  ],
};

export default ServiciosAudiovisuales;
