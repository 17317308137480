import React, { useLayoutEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import TarjetasBackground from '../../../assets/Icons/fondoTarjetas.svg';
import Camara from '../../../assets/Icons/Camara.svg';
import Yt from '../../../assets/Icons/yt.svg';
import servaud from '../../../assets/Icons/ServiciosAud.svg';
import InfoContainer from './InfoContainer';
import produccionAudiovisual from './Produccion.js';
import realizacionYStreaming from './Realizacion.js';
import ServiciosAudiovisuales from './ServiciosAud.js';
import { gtag, GA_MEASUREMENT_ID } from '../Analitycs/Analitycs';

const background = {
  backgroundImage: `url(${TarjetasBackground})`,
  backgroundSize: '100% 100%',
};

const Servicios = () => {
  gtag('config', GA_MEASUREMENT_ID, { page_path: '/produccion.html' });

  const prodAudiovisual = produccionAudiovisual;
  const realizacion = realizacionYStreaming;

  const prodAud = useRef(null);
  const events = useRef(null);
  const services = useRef(null);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToRef = ref => window.scrollTo(200, ref.current.offsetTop - 100);

  return (
    <div className="flex flex-col h-fit w-full back p-header px-0 pb-0">
      {/* Titulos */}
      <div className="flex flex-col justify-center items-center p-6">
        <p className="font-sanfran font-semibold text-4xl text-indexred mt-4 mb-4 text-center">
          Todo lo que podemos hacer por ti
        </p>
        <p className="font-sanfran font-light text-indexred text-base text-center w-full lg:w-7/12 leading-7">
          Toda nuestra capacidad productiva a tu servicio: spots, promocionales,
          corporativos, brand movies, todo tipo de formatos para redes,
          realización y streaming de eventos. Cuéntanos qué necesitas y te
          diremos cómo vamos a hacerlo.
        </p>
      </div>
      {/* Tarjetas Container */}
      <div className="flex flex-col justify-center items-center w-full h-fit py-16 relative">
        <div
          style={{ zIndex: 5 }}
          className="flex flex-col md:flex-row items-center justify-center w-full h-fit"
        >
          <motion.div
            onClick={() => scrollToRef(prodAud)}
            whileHover={{ scale: 1.07 }}
            style={{ boxShadow: '0px 6px 20px rgba(213, 0, 55, 0.11)' }}
            className="flex cursor-pointer flex-col justify-start items-center w-card h-card bg-white m-3 lg:mx-5 rounded-lg p-8"
          >
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-center object-scale-down"
            >
              <img style={{ height: '8rem' }} src={Camara} alt="" />
            </div>
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-start items-center"
            >
              <p className="font-sanfran text-indexred font-medium my-4">
                Producción audiovisual
              </p>
              <p className="font-sanfran text-gray-500 text-sm text-center font-light">
                Conoce los procesos que realizamos día a día para conseguir el
                vídeo más eficaz.
              </p>
            </div>
          </motion.div>
          <motion.div
            onClick={() => scrollToRef(events)}
            whileHover={{ scale: 1.07 }}
            style={{ boxShadow: '0px 6px 20px rgba(213, 0, 55, 0.11)' }}
            className="flex cursor-pointer flex-col justify-start items-center w-card h-card bg-white m-3 lg:mx-5 rounded-lg p-8"
          >
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-center object-scale-down"
            >
              <img style={{ height: '8rem' }} src={Yt} alt="" />
            </div>
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-start items-center"
            >
              <p className="font-sanfran text-indexred font-medium my-4">
                Realización y streaming
              </p>
              <p className="font-sanfran text-gray-500 text-sm text-center font-light">
                Realización, grabación, emisión y streaming de eventos en
                directo.
              </p>
            </div>
          </motion.div>
          <motion.div
            onClick={() => scrollToRef(services)}
            whileHover={{ scale: 1.07 }}
            style={{ boxShadow: '0px 6px 20px rgba(213, 0, 55, 0.11)' }}
            className="flex cursor-pointer flex-col justify-start items-center w-card h-card bg-white m-3 lg:mx-5 rounded-lg p-8"
          >
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-center object-scale-down"
            >
              <img style={{ height: '8rem' }} src={servaud} alt="" />
            </div>
            <div
              style={{ height: '8rem' }}
              className="h-8 w-full flex flex-col justify-start items-center"
            >
              <p className="font-sanfran text-indexred font-medium my-4">
                Servicios audiovisuales
              </p>
              <p className="font-sanfran text-gray-500 text-sm text-center font-light">
                Nuestra capacidad y medios a tu alcance. Estos son los servicios
                independientes que ofrecemos.
              </p>
            </div>
          </motion.div>
        </div>
        <img
          style={{ width: '100vw', height: '25rem' }}
          className="absolute hidden md:flex"
          src={TarjetasBackground}
          alt=""
        />
      </div>
      <InfoContainer
        ref={prodAud}
        data={prodAudiovisual}
        titulo="Producción audiovisual"
        withButtons
      />
      <InfoContainer
        ref={events}
        data={realizacionYStreaming}
        titulo="Realización y streaming"
      />
      <InfoContainer
        ref={services}
        data={ServiciosAudiovisuales}
        titulo="Servicios audiovisuales"
      />
    </div>
  );
};

export default Servicios;
