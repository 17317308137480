import { API } from '../../API';

const fetchVideosFolder = async name => {
  const carpetas = await API.get('/me/albums');

  const folderVideos = carpetas.data.data.reduce((accumulator, item) => {
    if (item.name === name) {
      const uri = item.uri.split('/');
      const ids = uri[4];
      return [...accumulator, ids];
    }
    return accumulator;
  }, []);

  const videos = Promise.all(
    folderVideos.map(async folder => {
      // ?per_page=99
      const video = await API.get(`/me/albums/${folder}/videos?per_page=99`, {
        params: {
          sort: 'manual',
        },
      });
      const idVideos = video.data.data.map(vid => {
        const id = vid.uri.split('/')[2];
        const vidId = {
          id,
          ...vid,
        };
        return vidId;
      });
      return idVideos;
    })
  );

  return videos;
};
export default fetchVideosFolder;
