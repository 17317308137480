/* eslint-disable react/button-has-type */
import React, { useState, useLayoutEffect } from 'react';
import { Card } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
import MiniCard from './MiniCard';

const InfoContainer = React.forwardRef(
  ({ data, titulo, withButtons = false }, ref) => {
    const [classes, setClasses] = useState(
      Object.entries(data).map(value => {
        return value[0];
      })
    );
    const [current, setCurrent] = useState(
      Object.entries(data).map(value => {
        return value[0];
      })[0]
    );

    return (
      <div
        ref={ref}
        className="flex flex-col items-center w-full h-full pb-32 bg-redbackground mt-32 mb-16"
      >
        {/* Title Holder */}
        <div className="flex flex-col py-3 focus:outline-none px-5 items-center bg-white border-2 border-indexred -mt-8 mb-6 rounded-lg">
          <p className="font-sanfran text-indexred text-2xl font-medium">
            {titulo}
          </p>
        </div>
        {withButtons && (
          <div className="flex flex-row flex-wrap items-center justify-center mb-6">
            <button
              onClick={() => setCurrent('Preproduccion')}
              className={
                current === 'Preproduccion'
                  ? 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full bg-indexred font-sanfran text-white text-sm'
                  : 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full font-sanfran text-gray-500 text-sm'
              }
            >
              Preproducción
            </button>
            <button
              onClick={() => setCurrent('Produccion')}
              className={
                current === 'Produccion'
                  ? 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full bg-indexred font-sanfran text-white text-sm'
                  : 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full font-sanfran text-gray-500 text-sm'
              }
            >
              Producción
            </button>
            <button
              onClick={() => setCurrent('Postproduccion')}
              className={
                current === 'Postproduccion'
                  ? 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full bg-indexred font-sanfran text-white text-sm'
                  : 'focus:outline-none px-3 mx-2 my-2 sm:mx-4 py-1 rounded-full font-sanfran text-gray-500 text-sm'
              }
            >
              Postproducción
            </button>
          </div>
        )}
        <AnimatePresence exitBeforeEnter>
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6 w-11/12 md:w-9/12">
            {data[current].map(value => {
              return (
                <MiniCard
                  key={value.Titulo}
                  titulo={value.Titulo}
                  descripcion={value.Descripcion}
                />
              );
            })}
          </div>
        </AnimatePresence>
      </div>
    );
  }
);

export default InfoContainer;
