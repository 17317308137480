import Laura from '../../../assets/photos/lauraforma.png';
import Rivillo from '../../../assets/photos/rivilloforma.png';
import Jose from '../../../assets/photos/joseforma.png';
import Alberto from '../../../assets/photos/albertoforma.png';

const teamInfo = [
  {
    imagen: Laura,
    nombre: 'Laura Llorens',
    puesto: 'Producción',
    email: 'laura@indexvideo.es',
    texto:
      'Para conseguir el mejor resultado es clave hacer una buena planificación previa y anticipar los máximos detalles en preproducción. Solo así conseguiremos que después la producción vaya sobre ruedas y podamos disfrutar el rodaje, aunque siempre haya que estar preparados y prevenidos para cualquier contratiempo que pueda surgir ese día.',
  },
  {
    imagen: Rivillo,
    nombre: 'Alejandro Rivillo',
    puesto: 'Comunicación',
    email: 'alejandro@indexvideo.es',
    texto:
      '¿Los ingredientes para un buen anuncio? De base una buena estrategia sincera. Hay que acotar un público real, definir unos objetivos claros y alcanzables y diseñar un mensaje audiovisual que resuelva las necesidades de la marca y el trabajo en sí. Es muy importante empatizar con el público, darle importancia a las emociones e intentar trasladar elementos que las provoquen a nuestro vídeo. Añadiendo durante todo el proceso unas cucharaditas de pasión, esfuerzo y creatividad conseguiremos la receta perfecta para que nuestro vídeo comunique, fidelice y sea recordado.',
  },

  {
    imagen: Alberto,
    nombre: 'Alberto Cabanes',
    puesto: 'Área técnica',
    email: 'alberto@indexvideo.es',
    texto:
      'Un evento requiere una gran cantidad de recursos técnicos, de iluminación, de grabación, de realización, de sonido... pero sobretodo un equipo humano experto que busque la excelencia en cada proyecto. En todo momento vamos de la mano de nuestros clientes, ya sea un pequeño encuentro empresarial o un gran evento, pero siempre ofreciendo nuestro mejor servicio profesional, adaptándolo a las necesidades y objetivos de cada uno de ellos.',
  },
  {
    imagen: Jose,
    nombre: 'José Sanjuan',
    puesto: 'Dirección',
    email: 'jose@indexvideo.es',
    texto:
      'Generamos contenidos audiovisuales para que las marcas seduzcan a sus clientes. Creo que el concepto de calidad en un vídeo va más allá de la creatividad, la estética, el mensaje… El mejor vídeo tiene que ser sobre todo eficaz, dar resultados. Por eso, dedico tiempo y aprendizaje en conocer nuevos lenguajes y formatos audiovisuales, tendencias narrativas y estéticas. Pienso que reinventarnos día a día y sin mirar atrás es clave para seguir creciendo. Ah!, y no nos conformamos con un “me gusta”; siempre buscamos el "¡WOW!"',
  },
];

export default teamInfo;
