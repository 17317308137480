import React from 'react';

const Cookie = ({ setCookiesDisabled, showCookieModal }) => {
  return (
    <div className="flex flex-row justify-center w-full h-fit bg-red-700">
      <div className="flex flex-col md:flex-row items-center justify-between md:w-fit mx-auto h-fit bg-black opacity-50 fixed bottom-0 z-50 p-5 rounded-lg mb-3">
        <p className="font-sanfran font-light text-white text-sm opacity-100">
          Este sitio web utiliza cookies propias y de terceros para analizar y
          mejorar tu experiencia de navegación.
        </p>
        <div className="flex flex-row mt-5 md:mt-0 opacity-100">
          <button
            onClick={() => showCookieModal(false)}
            className="px-5 py-2 text-white"
          >
            Rechazar
          </button>
          <button
            onClick={() => setCookiesDisabled(false)}
            className=" rounded px-5 py-2 border-solid border-2 text-white"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cookie;
